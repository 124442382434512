import React from 'react';

import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';
import linkResolver from '../utilities/link-resolver';

const PreviewPage = () => {
  return (
    <div>
      <h1>Loading preview…</h1>
    </div>
  );
};

export default withPrismicPreviewResolver(PreviewPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver: doc => linkResolver(doc),
  },
]);
